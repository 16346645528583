import React, { Component } from 'react';
import PropTypes from 'prop-types';
import withMobileDialog from '@material-ui/core/withMobileDialog';
import { Dialog, DialogTitle, DialogContent } from '@material-ui/core';
import { PdfViewer } from 'components/pdfViewer';

const PATH_FILES = process.env.REACT_APP_BASE_PATH_FILES_DOCS;

const url = `${PATH_FILES}PH/quotation/Maxicare Member Consent_Individual Signature.pdf`;
const logo = '/static/images/Asistensi_logotipo.svg';
class TermsOathStatementDialog extends Component {
	render() {
		const { fullScreen } = this.props;
		return (
			<Dialog
				open={this.props.open}
				maxWidth="md"
				fullWidth={true}
				onClose={this.props.handleClose}
				disableBackdropClick={true}
				disableEscapeKeyDown={true}
				fullScreen={fullScreen}
			>
				<DialogTitle className="text-gray-900 font-bold">
					<img src={logo} alt="asistensi_logotipo" width="160px" />
				</DialogTitle>
				<DialogContent>
					<PdfViewer url={url} />

					<div className="flex justify-evenly my-10">
						<button
							className="secondary-button-nav mr-2"
							onClick={() => this.props.handleClose(false)}
						>
							I do not accept{' '}
						</button>
						<button
							className="white-button-nav"
							onClick={() => this.props.handleClose(true)}
						>
							I agree{' '}
						</button>
					</div>
				</DialogContent>
			</Dialog>
		);
	}
}

TermsOathStatementDialog.propTypes = {
	fullScreen: PropTypes.bool.isRequired
};

export default withMobileDialog({ breakpoint: 'xs' })(TermsOathStatementDialog);
