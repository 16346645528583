export default {
	_id: null,
	kinship: 'TITULAR',
	first_name: '',
	middle_name: '',
	last_name: '',
	nationality: 'default',
	dni: '',
	second_nationality: 'default',
	second_dni: '',
	doc_dni: null,
	document_dni: null,
	sex: 'default',
	civil_status: 'default',
	email: '',
	email_confirm: true,
	email_updated: false,
	existPassword: true,
	mode: 'email',
	phone_one: '',
	birth_date: null,
	age: null,
	country: 'default',
	state: 'default',
	municipality: 'default',
	city: 'default',
	address: '',
	include_titular: false,
	id_user_creator: '',
	agree_policy_privacy: true,
	coupon: '',
	formErrors: {},
	total: 0,
	answers: [],
	questions: [],
	products: [],
	product_id: '',
	product_type: {},
	pending_validation: false,
	product_price: 0,
	openAlert: true,
	savingMessage: false, // Mensaje que se muestra cuando se esta guardando la data
	show_warning: false,
	complete_surveys: false,
	is_client: false,
	agree_policy_privacy: false,
	// for create account
	password: '',
	password_confirm: '',
	phil_health: true,
	phil_health_number: ''
};
