import React, { Component } from 'react';
import Router from 'next/router';
import jwt_decode from 'jwt-decode';

import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import TagManager from 'react-gtm-module';
import { FormControlLabel, Checkbox } from '@material-ui/core';

import * as actions from 'store/actions';
import { SelectSimpleOutlined, DateInput, TextInput } from 'components/commons';
import { SnackBar, SpinnerV, NotFoundLocation } from 'components/widgets';
import TablePlans from 'components/plans/TablePlans';
import QuotationBeneficiaries from 'components/plans/QuotationBeneficiaries';
import BaseLead from 'models/BaseLead';
import Validations from 'components/utils/Validations';
import { QuotationPrivacyPolicyDialog } from 'components/terms';
import BaseTitular from 'components/utils/BaseTitular';
import QuotationQuest from '../quotation/QuotationQuest';
import TermsOathStatementDialog from '../terms/TermsOathStatementDialog';
import formatPhonePH from 'components/utils/formatPhonePH';
import { CellPhoneInput } from '../commons';

const COUNTRY_CODE = process.env.REACT_APP_COUNTRY_CODE_RESUME;
const DEFAULT_CODE_TLF = process.env.REACT_APP_DEFAULT_CODE_TLF;

class FastQuotation extends Component {
	state = {
		step: 1,
		lead: BaseLead,
		structure: {
			country: this.props.quotation?.country,
			state: this.props.quotation?.state,
			municipality: this.props.quotation?.municipality,
			city: this.props.quotation?.city,
			product_id: '',
			product_type: {},
			products: [],
			questions: [],
			birth_date: null,
			price: 0
		},
		products: [],
		options: [],
		special_services: [],
		payment_methods: [],
		openAlert: false,
		messageAlert: '',
		loading: false,
		dialog: false,
		dialogStatement: false,
		countryCode: '',
		leadDialog: false,
		municipalities: [],
		cities: []
	};
	_isMounted = false;

	async componentDidMount() {
		this._isMounted = true;
		this.setState({
			countryCode: COUNTRY_CODE.toLowerCase()
		});
		if (this.props.states.length === 0) this.props.actions.availableStates();

		if (
			this.props.quotation.state !== 'default' &&
			this.props.quotation.state &&
			this.props.states
		) {
			const result = await this.props.actions.availableMunicipalities(
				this.props.states.find(
					(state) => state.name === this.props.quotation.state
				)?.code
			);
			if (!result.success)
				this.setState({
					messageAlert: result.messageAlert,
					openAlert: result.openAlert
				});
			else this.setState({ municipalities: result.municipalities });
		}

		if (
			this.props.quotation.municipality !== 'default' &&
			this.props.quotation.municipality &&
			this.state.municipalities
		) {
			const result = await this.props.actions.availableCities(
				this.state.municipalities.find(
					(municipalities) =>
						municipalities.name === this.props.quotation.municipality
				).code
			);

			if (!result.success)
				this.setState({
					messageAlert: result.messageAlert,
					openAlert: result.openAlert
				});
			else this.setState({ cities: result.cities });
		}

		// fetching initial data
		if (this.props.mobileToken || this.props.token) {
			if (!this.props.titular.email) {
				if (this._isMounted) await this.getData();
			} else {
				// get data from token
				const { data } = jwt_decode(this.props.mobileToken || this.props.token);

				const { first_name, last_name, phone_one, email } = this.props.titular;

				if (this._isMounted)
					this.setState({
						lead: {
							...this.state.lead,
							// for some reason it did not allow the previous object to be set by default
							first_name: first_name || data?.first_name,
							last_name: last_name || data?.last_name,
							phone_one: phone_one || data?.phone_one,
							email: email || data?.email
						}
					});
			}
		}
	}

	componentWillUnmount() {
		this._isMounted = false;
	}

	/*Get titular Data. (Case:titular logged and quote)*/
	getData = async () => {
		this.setState({ loading: true });
		let data = await this.props.actions.getTitularData({
			headers: {
				Authorization: `Bearer ${this.props.mobileToken || this.props.token}`,
				country_code: COUNTRY_CODE,
				'Accept-Language': 'en-US'
			}
		});
		if (data.success === true) {
			const titular = data.titular;
			let { titular_email } = this.props;
			let saveTitular = { ...BaseTitular, ...titular };
			const { first_name, last_name, phone_one, email } = saveTitular;
			this.setState({
				lead: {
					...this.state.lead,
					first_name,
					last_name,
					phone_one,
					email: email || titular_email
				}
			});
			this.setState({ loading: false });
			this.props.actions.handleStateAffiliation(
				'payment_info',
				data.payment_info
			);
			if (saveTitular.first_name) {
				saveTitular.formErrors = [];
				saveTitular.email = email || titular_email;
				if (saveTitular && saveTitular.share_link) {
					await this.props.actions.SaveSharedLink({
						share_link: saveTitular.share_link,
						share_link_discount: saveTitular.share_link_discount
					});
				}
				if (saveTitular.doc_dni) saveTitular.document_dni = 'Uploaded file';
				this.props.actions.handleStateAffiliation('titular', {
					...BaseTitular,
					...saveTitular
				});
			} else {
				saveTitular.formErrors = [];
				saveTitular.email = email || titular_email;
				this.props.actions.handleStateAffiliation('titular', {
					...BaseTitular,
					...saveTitular
				});
			}
			if (
				saveTitular.include_titular === true &&
				!saveTitular.questions &&
				saveTitular.product_type
			) {
				saveTitular.questions = saveTitular.product_type.questions;
				this.props.actions.handleStateAffiliation('titular', {
					...BaseTitular,
					...saveTitular
				});
			}
		} else {
			this.setState({ loading: false });
			this.setState({ openAlert: data.openAlert });
			this.setState({ messageAlert: data.openAlert });
		}
	};

	handleInput = (event, step = 1) => {
		const { name, value } = event.target;
		let { quotation } = this.props;
		let { lead } = this.state;
		quotation[name] = value.value ? value.value : value;
		lead.city_interest = quotation.city;
		this.setState({ lead });

		if (name === 'state') {
			this.setState({ structure: { ...this.state.structure, state: value } });
			quotation.municipality = 'default';
			quotation.city = 'default';
			if (value === 'OTHER') {
				this.setState({ municipalities: [], cities: [] });
			} else {
				this.setState(
					{
						stateId:
							this.props.states.find((state) => state.name === value).code || ''
					},
					async () => {
						const result = await this.props.actions.availableMunicipalities(
							this.state.stateId
						);
						if (result?.success) {
							this.setState({ municipalities: result.municipalities });
						} else {
							this.setState({ ...result });
							this.setState({ municipalities: [] });
						}
					}
				);
			}
		}

		if (name === 'municipality') {
			this.setState({
				structure: { ...this.state.structure, municipality: value }
			});
			quotation.city = 'default';
			if (value === 'OTHER') {
				this.setState({ cities: [] });
			} else {
				const municipalityId = this.state.municipalities.find(
					(mun) => mun.name === value
				).code;
				this.setState({ municipalityId: municipalityId }, async () => {
					const result =
						(await this.props.actions.availableCities(
							this.state.municipalityId
						)) || '';
					if (result?.success) {
						this.setState({ cities: result.cities });
					} else {
						this.setState({ ...result });
						this.setState({ cities: [] });
					}
				});
			}
		}

		if (name === 'city') {
			this.setState({ structure: { ...this.state.structure, city: value } });
			quotation.city = value;
		} else if (name === 'city' && !value) {
			quotation.city = '';
		}

		if (name === 'amount') {
			let { parients } = quotation;
			let baseParients = parients;
			for (const parient in parients)
				if (parients[parient]['birth_date'] === null)
					baseParients.splice(parient, 1);
			for (
				let index = parients.length + 1;
				index <= quotation.amount;
				index++
			) {
				quotation.parients.push({ ...this.state.structure });
			}
			if (parients.length > quotation.amount) {
				let difference = parients.length - quotation.amount;
				for (let index = 0; index < difference; index++)
					quotation.parients.pop();
			}
		}
		this.props.actions.saveQuotation(quotation);
		this.setState({ step });
	};

	handleInputLead = (event, telNumber) => {
		let lead = this.state.lead;
		if (event === 'phone_one') {
			lead.phone_one = formatPhonePH(telNumber);
			Validations.validateFieldHeadLineRequired(
				lead,
				this.saveState,
				'phone_one',
				telNumber
			);
			if (lead.phone_one === '+' || lead.phone_one === DEFAULT_CODE_TLF)
				delete lead.formErrors.phone_one;
		} else {
			let { name, value } = event.target;
			lead[name] = value;
			Validations.validateFieldHeadLineRequired(
				lead,
				this.saveState,
				name,
				value
			);
		}
		this.setState({ lead });
	};

	handleDate = (date, index, name, step = 1) => {
		let { quotation } = this.props;
		quotation.parients[index][name] = date;

		// if (
		// 	!isNaN(new Date(date)) &&
		// 	date !== null &&
		// 	new Date().getFullYear() - new Date(date).getFullYear() > 85
		// ) {
		// 	this.setState({
		// 		messageAlert: 'There is not a plan for this age.',
		// 		openAlert: true
		// 	});
		// }

		// this.props.actions.saveQuotation(quotation);
		this.setState({ step });
	};

	handleGetProducts = async (country) => {
		this.setState({ loading: true });
		const result = await this.props.actions.getProducts(country);
		this.setState({ ...result, loading: false });
	};

	handleChangeStep = async (step) => {
		const { quotation, fromHome } = this.props;
		if (step === 2 && fromHome === true) {
			const event_quotation = {
				dataLayer: {
					event: 'evento-cotizacion-home-uno'
				}
			};
			TagManager.dataLayer(event_quotation);
		}

		this.props.actions.handleStateAffiliation('quotation', quotation?.parients);
		this.handleGetProducts(quotation?.country);
		this.handleSubmit();
		this.setState({ step });
	};

	handleSubmit = async () => {
		let { lead } = this.state;
		let { titular } = this.props;
		lead.marketing_campaign = this.props.marketing_campaign;
		lead.origin = this.props.origin;
		lead.share_link = this.props.share_link;
		lead.share_link_discount = this.props.share_link_discount;
		titular.first_name = lead.first_name;
		titular.last_name = lead.last_name;
		titular.email = lead.email;
		titular.phone_one = lead.phone_one;
		if (Router?.router?.query) lead = { ...Router?.router?.query, ...lead };
		this.setState({ spinnerScreen: true, lead });
		const response = await this.props.actions.createNewLead(lead);
		if (response.success) this.props.actions.saveLeadEmail(lead.email);
		await this.props.actions.handleStateAffiliation('titular', titular);
		this.setState({ spinnerScreen: false, lead });
	};

	handleCheck = (value, name) => {
		let lead = this.state.lead;
		lead[name] = value;
		this.setState({ lead });
	};

	closeDialogStatement = (check) => {
		this.handleCheck(check, 'agree_policy_privacy_statement');
		this.setState({ dialogStatement: false });
	};

	handleClose = () => this.setState({ openAlert: false });

	handleClosePrivacy = (e, reason) => {
		if (reason === 'backdropClick') {
			return false;
		}

		if (reason === 'escapeKeyDown') {
			return false;
		}

		if (!reason) {
			this.setState({ dialog: false });
		}
	};

	saveState = (state, data) => this.setState(data);

	showDialog = () => {
		document.body.classList.add('modal-open');
		this.setState({ leadDialog: true });
	};

	closeDialog = () => {
		document.body.classList.remove('modal-open');
		this.setState({ leadDialog: false });
	};

	handlePhone = (telNumber, countryCode) => {
		this.setState({ selectedCountryCode: countryCode });
		this.handleInputLead('phone_one', telNumber);
	};

	disableNextStep = () => {
		const { lead } = this.state;
		const { quotation } = this.props;

		return !lead.first_name ||
			!lead.last_name ||
			!lead.email ||
			!lead.phone_one ||
			lead.agree_policy_privacy === false ||
			lead.agree_policy_privacy_statement === false ||
			Object.keys(lead.formErrors).length > 0 ||
			quotation?.country === 'default' ||
			quotation?.state === 'default' ||
			quotation?.state === 'OTHER' ||
			quotation?.municipality === 'default' ||
			quotation?.municipality === 'OTHER' ||
			quotation?.city === 'default' ||
			quotation?.city === 'OTHER' ||
			quotation?.amount === 'default'
			? true
			: false;
	};

	checkBirth = () => {
		const { quotation } = this.props;
		if (
			quotation?.parients.find(
				(p) =>
					isNaN(new Date(p?.birth_date)) ||
					p?.birth_date === null ||
					new Date(p.birth_date).getTime() > new Date().getTime()
				// new Date().getFullYear() - new Date(p.birth_date).getFullYear() > 85
			)
		) {
			return true;
		} else {
			return false;
		}
	};

	isValidDate = (dateString) => {
		if (dateString != null) {
			var date = new Date(dateString);
			return date instanceof Date && !isNaN(date.valueOf());
		} else {
			return false;
		}
	};

	render() {
		const { quotation, quotation_affiliation, titular } = this.props;
		const {
			step,
			products,
			options,
			special_services,
			payment_methods,
			openAlert,
			messageAlert,
			loading,
			lead,
			dialog,
			dialogStatement,
			countryCode,
			leadDialog
		} = this.state;

		return (
			<div className="w-full" id="cotizar">
				<style global jsx>
					{`
						.quote.telInputFull,
						.quote.telInputErrorFull {
							margin: 0;
							width: 100% !important;
						}
						.quote.telInputFull::before,
						.quote.telInputErrorFull::before {
							content: 'Valid phone number *' !important;
						}
						@media only screen and (min-width: 600px) and (max-width: 959px) {
							.quote.telInputFull,
							.quote.telInputErrorFull {
								width: 100% !important;
							}
						}
					`}
				</style>
				{loading && <SpinnerV />}

				{/* {step === 1 && (
					<p className="text-gray-800 leading-relaxed text-lg py-10 font-poppins">
						Would you like to get a quote before purchasing our emergency
						medical insurance in the Philippines?
						<br />
						We need some information in order to provide you with the best quote
					</p>
				)} */}

				<p className="text-base font-poppins text-center font-bold text-pink-500">
					{step === 1 && 'Get your quote in seconds'}
					{step === 2 && 'Get your quote online!'}
				</p>
				<h2 className="text-3xl md:text-4xl text-center font-bold font-poppins text-purple-500 mt-2 mb-6">
					{step === 1 &&
						'In which city in the Philippines does your family member live?'}
					{step === 2 && 'Thanks for listing! Almost there'}
				</h2>
				{step == 1 && (
					<div className="flex flex-wrap">
						<SelectSimpleOutlined
							name="state"
							value={quotation.state}
							label="Province"
							placeholder="Select province"
							simpleList={this.props.states.map((s) => s.name)}
							addClass={`wInputThree ${
								quotation.state === 'default' && 'default'
							}`}
							onChange={(e) => this.handleInput(e)}
							otherOption={{
								value: 'OTHER',
								name: 'My province does not appear in the list'
							}}
							required={true}
							disabled={this.props.states.length === 0}
						/>

						<SelectSimpleOutlined
							name="municipality"
							value={quotation.municipality}
							label="City"
							placeholder="Select city"
							simpleList={this.state.municipalities.map((s) => s.name)}
							addClass={`wInputThree ${
								quotation.municipality === 'default' && 'default'
							}`}
							onChange={(e) => this.handleInput(e)}
							otherOption={
								quotation?.state !== 'default' && quotation?.state !== 'OTHER'
									? {
											value: 'OTHER',
											name: 'My city does not appear in the list'
									  }
									: null
							}
							required={true}
						/>

						<SelectSimpleOutlined
							name="city"
							value={quotation.city}
							label="Barangay"
							placeholder="Select barangay"
							simpleList={this.state.cities.map((s) => s.name)}
							addClass={`wInputThree ${
								quotation.city === 'default' && 'default'
							}`}
							onChange={(e) => {
								this.handleInput(e);
							}}
							otherOption={
								quotation?.municipality !== 'default' &&
								quotation?.municipality !== 'OTHER'
									? {
											value: 'OTHER',
											name: 'My barangay does not appear in the list'
									  }
									: null
							}
							required={true}
						/>

						<SelectSimpleOutlined
							name="amount"
							value={quotation.amount}
							label="Number of family members to quote"
							placeholder="Select the number of family members to quote"
							simpleList={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]}
							addClass={`wInputThree ${
								quotation.amount === 'default' && 'default'
							}`}
							onChange={(e) => this.handleInput(e)}
							required={true}
						/>
						{quotation?.amount !== 'default' && (
							<>
								{quotation?.parients.map((p, i) => {
									return (
										<DateInput
											key={i}
											clearable={true}
											required={true}
											name="birth_date"
											errors={!this.isValidDate(p?.birth_date)}
											label="Date of birth of person needing coverage"
											placeholder="MM/DD/YYYY"
											maxDate={new Date()}
											minDate={new Date('1900-01-01')}
											value={p?.birth_date}
											addClass="wInputThree"
											onChange={(date) => {
												this.handleDate(date, i, 'birth_date');
											}}
										/>
									);
								})}
							</>
						)}
						<TextInput
							name="first_name"
							label="First Name"
							placeholder="Enter your first name"
							value={lead.first_name}
							helperText={lead.formErrors?.first_name}
							errors={lead.formErrors?.first_name}
							addClass="wInputThree"
							onChange={this.handleInputLead}
							required={true}
							disabled={
								(this.props.mobileToken || this.props.token) &&
								titular?.first_name
									? true
									: false
							}
						/>
						<TextInput
							name="last_name"
							label="Last Name"
							placeholder="Enter your last name"
							value={lead.last_name}
							helperText={lead.formErrors?.last_name}
							errors={lead.formErrors?.last_name}
							addClass="wInputThree"
							onChange={this.handleInputLead}
							required={true}
							disabled={
								(this.props.mobileToken || this.props.token) &&
								titular?.last_name
									? true
									: false
							}
						/>
						<TextInput
							name="email"
							type="email"
							label="Email address"
							placeholder="Enter your email"
							value={lead.email}
							helperText={lead.formErrors?.email}
							errors={lead.formErrors?.email}
							addClass="wInputThree"
							onChange={this.handleInputLead}
							required={true}
							disabled={
								(this.props.mobileToken || this.props.token) && titular?.email
									? true
									: false
							}
						/>
						{countryCode && (
							<CellPhoneInput
								label="Mobile number"
								addClass="wInputThree"
								value={lead.phone_one}
								onChange={this.handlePhone}
								error={lead.formErrors?.phone_one}
								disabled={
									(this.props.mobileToken || this.props.token) &&
									titular.phone_one
										? true
										: false
								}
								required={true}
							/>
						)}
						<div className="flex items-center accept-terms mt-4 w-full justify-start md:mt-0 ml-2">
							<FormControlLabel
								label={
									<span
										className={`${
											this.props.mobileToken ? 'text-sm' : 'text-base'
										} 'w-full accept-terms-text'`}
									>
										I accept the
										<a
											className={
												'inline ml-1 font-bold underline cursor-pointer text-pink-500'
											}
											onClick={() => this.setState({ dialog: true })}
										>
											privacy policy
										</a>
									</span>
								}
								control={
									<Checkbox
										className={
											lead.agree_policy_privacy === false
												? 'checkBoxWhite'
												: lead.agree_policy_privacy === true
												? 'checkBoxActive'
												: 'checkBox'
										}
										checked={lead.agree_policy_privacy}
										onClick={() =>
											this.handleCheck(
												lead.agree_policy_privacy === true ? false : true,
												'agree_policy_privacy'
											)
										}
										name="agree_policy_privacy"
										value={lead.agree_policy_privacy}
										inputProps={{
											'aria-label': 'text-base',
											title: 'Check this box if you wish to continue'
										}}
									/>
								}
							/>
						</div>

						<div className="flex items-center accept-terms mt-4 w-full justify-start md:mt-0 ml-2">
							<FormControlLabel
								label={
									<span
										className={`${
											this.props.mobileToken ? 'text-sm' : 'text-base'
										} 'w-full accept-terms-text text-left'`}
									>
										I accept the
										<a
											className={
												'inline ml-1 font-bold underline cursor-pointer text-pink-500'
											}
											onClick={() => this.setState({ dialogStatement: true })}
										>
											declarations and authorisations for the subscription of
											the plan
										</a>
									</span>
								}
								control={
									<Checkbox
										className={
											lead.agree_policy_privacy_statement === false
												? 'checkBoxWhite'
												: lead.agree_policy_privacy_statement === true
												? 'checkBoxActive'
												: 'checkBox'
										}
										checked={lead.agree_policy_privacy_statement}
										onClick={() => this.setState({ dialogStatement: true })}
										name="agree_policy_privacy_statement"
										value={lead.agree_policy_privacy_statement}
										inputProps={{
											'aria-label': 'text-base',
											title: 'Check this box if you wish to continue'
										}}
									/>
								}
							/>
						</div>

						{(quotation?.state === 'OTHER' ||
							quotation?.municipality === 'OTHER' ||
							quotation?.city === 'OTHER') && (
							<div className="bg-purple-200 text-purple-500 text-sm leading-relaxed p-5 rounded mt-10 mx-auto">
								If your city does not appear it is because we are still working
								to include it within the scope of asistensi, leave us your data
								<b
									className="cursor-pointer ml-1"
									onClick={() => this.showDialog()}
								>
									here
								</b>
								.
							</div>
						)}
						<div className="flex justify-center w-full mt-12">
							<button
								className={'primary-button'}
								onClick={() => {
									if (this.checkBirth()) {
										this.setState({
											openAlert: true,
											messageAlert:
												'Please check the date of birth. E.g. MM/DD/YYYY'
										});
									} else {
										this.handleChangeStep(2);
									}
								}}
							>
								Get your quote
							</button>
						</div>

						{/* <div className="mb-12">
							<QuotationQuest />
						</div> */}
					</div>
				)}
				{step === 2 && (
					<div className="mt-12">
						<TablePlans
							products={products}
							options={options}
							special_services={special_services}
							onHandleChangeStep={this.handleChangeStep}
						/>
						{payment_methods?.length > 0 && (
							<QuotationBeneficiaries
								payment_methods={payment_methods}
								quotation={quotation_affiliation}
								quotationForSend={quotation}
								handleSave={this.props.actions.handleStateAffiliation}
								products={products}
								productsQuotation={this.props.actions.productsQuotation}
								saveQuotation={this.props.actions.saveQuotation}
								setMembershipOrigin={this.props.actions.setMembershipOrigin}
								fromLanding={this.props.fromLanding}
								token={this.props.mobileToken || this.props.token}
								user_type={this.props.user_type}
								isAlly={
									this.props.isAlly ||
									this.props.isAllyMaster ||
									this.props.isAllyMatrix
								}
								fromHome={this.props.fromHome}
								share_link_discount={this.props.share_link_discount}
							/>
						)}
					</div>
				)}
				<QuotationPrivacyPolicyDialog
					open={dialog}
					handleClose={this.handleClosePrivacy}
				/>
				<TermsOathStatementDialog
					open={dialogStatement}
					handleClose={this.closeDialogStatement}
				/>
				<SnackBar
					openAlert={openAlert}
					messageAlert={messageAlert}
					handleClose={this.handleClose}
				/>
				{leadDialog && (
					<NotFoundLocation lead={lead} handleClose={this.closeDialog} />
				)}
			</div>
		);
	}
}

function mapStateToProps(state, props) {
	const quotation = state.customers.get('quotation');
	const quotation_affiliation = state.affiliation.get('quotation');
	const titular = state.affiliation.get('titular');
	const token = state.affiliation.get('token');
	const user_type = state.affiliation.get('user_type');
	const share_link = state.ally.get('share_link');
	const share_link_discount = state.ally.get('share_link_discount');
	const titular_email = state.affiliation.get('email');
	const isAllyMatrix =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 111;
	const isAllyMaster =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 110;
	const isAlly =
		state.login.get('id_user') && parseInt(state.login.get('type')) === 100;
	const states = state.country.get('states');
	return {
		quotation,
		quotation_affiliation,
		titular,
		token,
		user_type,
		share_link,
		share_link_discount,
		isAllyMatrix,
		isAllyMaster,
		isAlly,
		titular_email,
		states
	};
}

function mapDispatchToProps(dispatch) {
	return {
		actions: bindActionCreators(actions, dispatch)
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(FastQuotation);
